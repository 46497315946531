.profile {
  display: grid;
  gap: 2rem;
  padding: 1rem;

  & .user {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    & .userForm {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      & .nameInput,
      & .mobileInput {
        width: 100%;
        border: 1px solid rgba(215, 215, 215, 1);
        border-radius: 0.625rem;
        padding: 0.5rem;
      }

      & .mobileDropDown {
        display: flex !important;
      }

      & #profileBtn {
        text-align: end;
        background: transparent;
        color: rgba(28, 161, 70, 1);
        font-weight: 600;
        padding: 0;
      }
    }
  }

  & .photo {
    color: rgba(102, 102, 102, 1);
    cursor: pointer;
    width: fit-content;
  }

  & .icon {
    font-size: 1.3rem;
  }

  & .textDiv {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  & .others {
    cursor: pointer;
    display: grid;
    gap: 1rem;

    & .title {
      font-size: 0.8rem;
      color: rgba(102, 102, 102, 1);
      text-transform: uppercase;
      cursor: default;
    }
  }

  & .options {
    display: grid;
    border: 1px solid rgba(215, 215, 215, 0.32);
    border-radius: 0.625rem;
    padding: 0.75rem 0rem;
    gap: 0.75rem;

    & .history {
      display: flex;
      justify-content: space-between;
      padding: 0rem 1rem;
      border-bottom: 1px solid rgba(215, 215, 215, 0.32);
    }

    & .policy {
      display: flex;
      justify-content: space-between;
      padding: 0rem 1rem;
      border-bottom: 1px solid rgba(215, 215, 215, 0.32);
    }

    & .help {
      display: flex;
      justify-content: space-between;
      padding: 0rem 1rem;
    }
  }

  & .menu-item {
    display: flex;
    border: 1px solid rgba(215, 215, 215, 0.32);
    border-radius: 0.625rem;
    align-items: center;
    padding: 0.5rem 1rem;
    gap: 0.75rem;
    color: rgba(211, 62, 34, 1);
    cursor: pointer;

    & i {
      color: rgba(211, 62, 34, 1);
    }
  }
}
