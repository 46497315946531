.home-wrapper {
  max-width: 500px;
  margin: 0 auto;
  background-color: white;
}

.home-wrapper header {
  position: sticky;
  top: 0;
}

.home-wrapper main {
  padding: 1.25rem;
  min-height: 100vh;
}

.home-wrapper footer {
  position: sticky;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  text-align: center;
  border-top: 1px solid #eeeeee;
}

.home-wrapper footer > * {
  flex: 1 1 auto;
  background-color: var(--bs-body-bg);
  color: var(--inactive-link-color);
  text-decoration: none;
}

.home-wrapper footer > .active {
  color: var(--bs-primary);
}
