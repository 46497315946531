.filterModal {
  & .modal-content {
    padding: 0px;
    max-width: 150px;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid #D7D7D752;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
}

.modal-body {
  --bs-modal-padding: 0px;
}

.form-check {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
}

.form-check .form-check-input {
  border: 2px solid #5f6368;
}

.filters {
  padding: 0.75rem 1rem;
}

.filters:not(:last-child) {
  border-bottom: 1px solid rgba(60, 60, 67, 0.36);
}