.bids-overview {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  place-items: center;
  padding: 0.75rem 1.25rem;
  border-radius: 10px;
  text-align: center;
  color: #ffffff;
  background-image: url("/public/assets/backgrounds/overview-bg.png");
  background-size: cover;
  margin-bottom: 1.25rem;
}

.bids-overview > div {
  position: relative;
  width: 100%;
}

.bids-overview > div p:first-child {
  font-size: 1.75rem;
}

.bids-overview > div:not(:last-child):after {
  content: "";
  width: 1px;
  height: 33%;
  background-color: #ffffff;
  opacity: 0.2;
  position: absolute;
  right: -1px;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
