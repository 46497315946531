.totalCount {
  display: flex;
  gap: 0.75rem;
  align-items: center;

  & .text {
    font-size: 1.3rem;
    font-weight: 600;
    flex: 1;
  }

  & .filterOn {
    color: rgba(211, 62, 34, 1);
  }

  & .filter {
    background: transparent;
    width: max-content;
  }
}
