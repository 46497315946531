.bid-type {
  display: flex;
  gap: 0.5rem;

  & button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex: 1;
    background: transparent;
    border-radius: 0.625rem;
    border: 1px solid rgba(215, 215, 215, 1);

    & svg {
      width: 1.125em;
      height: 1.3125em;
    }

    &.active {
      border-radius: 0.625rem;
      border: 1px solid rgba(211, 62, 34, 1);
      color: red;
    }
  }
}