.place-bid form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  padding-block: 1rem;
}

.place-bid form .input-group input {
  display: block;
  font-size: 1.25rem;
  background-color: #ffffff;
  padding: 1em;
}

.place-bid form .input-group .input-group-text {
  background-color: #ffffff;
  padding: 0.75em 1em;
  font-size: 1.25rem;
}

.place-bid .bid-difference {
  color: #666666;
}