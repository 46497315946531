.login {
  padding: 1rem;
}

.login .login-card form > div {
  margin-block: 1rem;
}

.login .login-card form input {
  appearance: none;
  display: block;
  width: 100%;
  padding: 0.75em 0.5em;
  border: 1px solid black;
}

.login .login-card button {
  display: block;
  padding: 0.5em 1em;
  background-color: var(--primary-color);
  color: var(--primary-contrast-text);
  border: 0px;
  border-radius: 8px;
  margin: 1rem auto;
  width: 100%;
}

.login .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.login .dropdown-toggle {
  --bs-btn-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}
