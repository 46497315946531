.otp-input {
  gap: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

.otp-input input {
  width: 100%;
  padding: 1em;
  text-align: center;
  border: 1px solid var(--inactive-color);
  border-radius: 10px;
}

.otp-input input.has-value {
  border: 1px solid var(--bs-success);
}

.otp-input.has-error input {
  border: 1px solid var(--bs-danger);
}
