.splash {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.splash h1 {
  font-family: "Bakbak One", Arial, sans-serif;
}

.splash .footer img {
  display: inline-block;
  width: 4rem;
  aspect-ratio: 4/1;
  vertical-align: middle;
  margin-inline: 0.25rem;
}

.splash .footer p {
  font-family: "Rubik", Arial, sans-serif;
  font-size: 14px;
}
