.product-details {
    position: relative;
    display: flex;
    margin-top: 2rem;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgba(215, 215, 215, 0.5);
    border-radius: 0.625rem;
    padding: 0.5rem 0.75rem;

    & .divider {
        width: 1px;
        height: 50%;
        background-color: var(--inactive-color);
    }

    & .expiry {
        position: absolute;
        display: flex;
        gap: 0.5rem;
        z-index: 100;
        background: white;
        top: -1rem;
        align-items: center;
        border: 1px solid rgba(215, 215, 215, 0.5);
        border-radius: 1.6rem;
        padding: 0.25rem 0.75rem;

        & p {
            color: black;
            font-size: 0.85rem;
            font-weight: 400;
        }
    }

    & .product-info {
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr) auto minmax(0, 1fr);
        text-align: center;
        width: 100%;
        align-items: center;
        padding: 1.5rem 0rem 0rem 0rem;
    }

    & h5 {
        font-size: 0.8rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
    }

    & p {
        font-size: 1em;
        font-weight: 500;
        color: rgba(28, 161, 70, 1);
    }
}

.product-details .font-H {
    font-size: 1.3rem;
}

.product-details .font-K {
    font-size: 1rem;
}

.product-details .font-M {
    font-size: 0.75rem;
}