@import "custom";
@import "react-datepicker/dist/react-datepicker.css";

:root {
  --primary-color: #d33e22;
  --secondary-color: #cf8e80;
  --tertiary-color: #052f5f;
  --primary-color-light: #fff0ed;
  --primary-color-1: #2e86ab;

  --primary-contrast-text: #ffffff;

  --bold: 600;
  --semibold: 500;
  --normal: 400;
  --lighter: 300;

  --inactive-color: #d7d7d7;
  --inactive-link-color: #5f6368;

  --spacing-1: 0.625rem;
}

.app {
  background-color: #f5f5f5;
}

.app > div {
  min-height: 100vh;
  background-color: #ffffff;
  background-image: url("../public/assets/backgrounds/background.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  width: min(430px, 100%);
  margin: 0 auto;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

div {
  scrollbar-width: none;
}

label {
  display: block;
  font-weight: var(--semibold);
}

button {
  background: none;
  border: none;
  padding-block: 0.5em;
  height: max-content;
}

p {
  margin: 0;
}

button[type="submit"] {
  display: block;
  background-image: linear-gradient(251.66deg, #d33e22 8.61%, #f58634 103.17%);
  border: none;
  border-radius: 10px;
  width: 100%;
  margin-block: 1rem;
  padding-block: 0.75rem;
  font-weight: var(--bold);
}

button[type="submit"]:disabled {
  background-image: none;
}

.btn-primary {
  --bs-btn-disabled-bg: #d7d7d7;
  --bs-btn-disabled-opacity: 1;
}

button.primary {
  background-color: var(--primary-color);
  color: var(--primary-contrast-text);
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.input-group > .form-control,
.input-group > .dropdown-toggle {
  --bs-border-radius: 10px;
  padding: 0.75rem;
  display: flex;
  align-items: center;
}

.success {
  color: var(--bs-success);
}

.danger {
  color: var(--bs-danger);
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.link-button {
  border: none;
  background: none;
  padding: 0px;
  margin: 0px;
}

.icon-button {
  border: none;
  background: none;
  padding: 0px 1em;
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23d33e22' d='M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m0 14A6 6 0 1 1 8 2a6 6 0 0 1 0 12M5 8a3 3 0 1 1 6 0a3 3 0 0 1-6 0'/%3E%3C/svg%3E");
  color: #d33e22;
  background-color: transparent;
  border: none;
}

.modal-content {
  padding: 1.25rem 1rem;

  & button {
    padding-bottom: 0px;
  }
}