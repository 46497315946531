.search-input {
  margin-bottom: 1.25rem;
}

.search-input input {
  width: 100%;
  padding: 1em;
  border-left: 0px;
  border-radius: 10px;
  border-color: #d7d7d780;
  box-shadow: 0px 4px 4px 0px #00000005;
}

.search-input input:focus {
  box-shadow: none;
  border-color: #d7d7d780;
}

.search-input .input-group-text {
  background: none;
  border-color: #d7d7d780;
  border-right: 0px;
  border-radius: 10px;
  padding: 1rem 0rem 1rem 1rem;
}

.search-input input.has-value {
  border: 1px solid var(--bs-success);
}

.search-input.has-error input {
  border: 1px solid var(--bs-danger);
}
