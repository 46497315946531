.product-item {
  display: grid;
  grid-template-columns: min(25%, 95px) minmax(0, 1fr);
  border: 1px solid #d7d7d780;
  border-radius: 10px;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0px 4px 4px 0px #00000005;
  background-color: #ffffff;
}

.product-item h5 {
  font-weight: var(--normal);
  color: #000000;
  margin: 0px;
  line-height: 1.5;
}

.product-item p.highest-bid {
  font-size: 1rem;
  font-weight: var(--semibold);
  color: var(--bs-success);
}

.product-item p.highest-bid-label {
  font-size: 0.875rem;
  color: #666666;
}

.product-item p.countdown {
  background-color: var(--primary-color-light);
  color: var(--primary-color);
  width: max-content;
  padding: 0.25em 0.5em;
  border-radius: 4px;
  line-height: 1;
}

.product-item img {
  border-radius: 6px;
  aspect-ratio: 1/1;
  object-fit: cover;
  overflow-clip-margin: unset;
}

.product-item .product-item-detail .bid-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.75rem;
}

.product-item .product-item-detail .bid-section button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 0.875rem;
  padding: 0.2rem 0.5rem;
  background-image: linear-gradient(251.66deg, #d33e22 8.61%, #f58634 103.17%);
  color: #ffffff;
  border-radius: 6px;
}

.product-item .product-item-detail .bid-section svg {
  flex-basis: 1rem;
}
