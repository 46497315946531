.home {
  max-width: 500px;
  margin: 0 auto;
}

.home main {
  min-height: 100vh;
}

.home .products-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 0.75rem;
}

.home .products-header a {
  font-weight: var(--semibold);
  text-decoration: none;
}

.home .products-header h4 {
  margin: 0px;
}

.home footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  font-size: 2rem;
  text-align: center;
}

.home footer > * {
  flex: 1 1 auto;
  background-color: var(--bs-secondary-bg);
  color: var(--bs-secondary);
}

.home footer > .active {
  flex: 1 1 auto;
  background-color: var(--bs-body-bg);
  color: var(--bs-primary);
}
