.product-result {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  & img {
    border-radius: 10px;
    object-fit: cover;
    overflow-clip-margin: unset;
    height: 195px;
  }

  & .info {
    display: flex;
    flex-direction: column;

    & .details {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      & p {
        font-size: 0.875rem;
        color: rgba(102, 102, 102, 1);
      }

      & .divider {
        display: block;
        width: 4px;
        height: 4px;
        font-size: 0.875rem;
        background-color: #d9d9d9;
        border-radius: 50%;
      }
    }

    & .desc {
      font-size: 1.2rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
      margin-top: 1.5rem;
    }

    & .product-result {
      text-align: center;
      margin-top: 3rem;

      & p {
        font-size: 1.4rem;
      }

      & .won {
        color: rgba(28, 161, 70, 1);
      }

      & .lose {
        color: rgba(211, 62, 34, 1);
      }
    }
  }
}