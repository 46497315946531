.buttons {
  display: flex;
  gap: 2rem;
  justify-content: center;
  font-size: 1.2rem;

  & .modal-close-button-1 {
    color: rgba(28, 161, 70, 1);
    font-weight: 300;
  }

  & .modal-close-button-2 {
    color: rgba(28, 161, 70, 1);
    font-weight: 600;
    text-transform: uppercase;
  }
}
